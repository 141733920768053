<template>
  <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M33.574 23.3796C35.5761 16.0943 40.9815 14 47.6213 14C49.551 14 50.5526 14 52.0789 14.75C55.1316 16.25 57.8521 18.6926 59.7207 21.1209C62.7416 25.0467 62.778 29.3467 59.7207 34.7511C56.6634 40.1554 50.2221 42.5215 43.2073 39.9741C39.3868 38.5868 38.5089 37.9796 35.7656 34.7511L35.733 34.7127C34.2587 32.9777 33.1706 31.6972 33.0376 29.2457C32.9287 27.2395 33.0417 25.3165 33.574 23.3796Z" :fill="secondaryColor"/>
    <path d="M33.574 23.3796L32.8508 23.1808L33.574 23.3796ZM33.0376 29.2457L32.2887 29.2863L33.0376 29.2457ZM35.733 34.7127L36.3046 34.2271L36.3046 34.227L35.733 34.7127ZM35.7656 34.7511L35.1941 35.2367L35.1941 35.2367L35.7656 34.7511ZM43.2073 39.9741L42.9513 40.6791L43.2073 39.9741ZM59.7207 21.1209L59.1263 21.5783L59.7207 21.1209ZM47.6213 13.25C44.2348 13.25 41.0713 13.7819 38.4775 15.3118C35.8582 16.8568 33.897 19.3738 32.8508 23.1808L34.2972 23.5783C35.2531 20.1 36.9957 17.9273 39.2396 16.6038C41.5089 15.2653 44.368 14.75 47.6213 14.75V13.25ZM32.8508 23.1808C32.2911 25.2172 32.1768 27.2253 32.2887 29.2863L33.7865 29.205C33.6805 27.2536 33.7922 25.4158 34.2972 23.5783L32.8508 23.1808ZM32.2887 29.2863C32.4367 32.0129 33.6884 33.4647 35.1615 35.1984L36.3046 34.227C34.8291 32.4907 33.9046 31.3815 33.7865 29.205L32.2887 29.2863ZM35.1615 35.1983L35.1941 35.2367L36.3372 34.2655L36.3046 34.2271L35.1615 35.1983ZM35.1941 35.2367C36.5671 36.8526 37.526 37.8781 38.6521 38.6672C39.7802 39.4578 41.0375 39.9841 42.9513 40.6791L43.4633 39.2692C41.5567 38.5768 40.4648 38.1059 39.5129 37.4388C38.5591 36.7704 37.7074 35.8781 36.3372 34.2654L35.1941 35.2367ZM42.9513 40.6791C50.267 43.3357 57.114 40.882 60.3734 35.1204L59.0679 34.3818C56.2127 39.4288 50.1772 41.7073 43.4633 39.2692L42.9513 40.6791ZM60.3734 35.1204C61.9418 32.348 62.7549 29.793 62.75 27.38C62.745 24.9515 61.9115 22.7383 60.315 20.6635L59.1263 21.5783C60.5507 23.4294 61.2458 25.3291 61.25 27.3831C61.2542 29.4527 60.5568 31.7498 59.0679 34.3818L60.3734 35.1204ZM60.315 20.6635C58.3887 18.1601 55.5824 15.6359 52.4097 14.0769L51.7482 15.4231C54.6808 16.8641 57.3155 19.2251 59.1263 21.5783L60.315 20.6635ZM52.4097 14.0769C50.7127 13.243 49.5529 13.25 47.6213 13.25V14.75C49.5491 14.75 50.3926 14.757 51.7482 15.4231L52.4097 14.0769Z" :fill="secondaryColor"/>
    <path d="M16 46.4505C15.6 43.4218 15.04 36.4953 16 29.8585C21.8667 29.4635 36.08 28.9104 47.6 29.8585C47.8667 32.8872 48.24 40.9988 47.6 49.2158C42.9333 49.6108 29.8398 50.1639 13.1998 49.2158C12.5332 46.4505 11.5998 37.4433 13.1998 26.698C18.9332 26.1713 34.48 25.4339 50.8 26.698C51.2 29.5951 51.76 39.6556 50.8 51.9811C43.8666 52.7712 27.2 53.5613 13.1998 51.9811L8 58.302C15.4667 58.8287 35.52 59.5662 56 58.302L53.2 54.3515" :stroke="mainColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.33323 52.9986V37.004C7.33265 36.7849 7.15687 36.6079 6.93933 36.6073H5.71119C4.7296 36.6073 3.78748 36.2152 3.09184 35.5176C2.39626 34.8194 2.00351 33.8723 2.00002 32.8832C1.99654 31.8941 2.38233 30.9441 3.0727 30.2412L28.6187 6.29356C29.5475 5.46332 30.746 5.00351 31.9882 5.00001C33.2302 4.99709 34.4305 5.45106 35.3639 6.27663L60.8995 30.2123C61.6003 30.9117 61.9965 31.8628 62 32.8561C62.0035 33.85 61.6142 34.8035 60.918 35.5076C60.2219 36.2116 59.2756 36.6071 58.2895 36.6071H57.0613C56.8438 36.6077 56.668 36.7848 56.6674 37.0039V53" :stroke="mainColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'WorkAccommodation',
  props: {
    mainColor: {
      required: true,
      type: String,
    },
    secondaryColor: {
      required: true,
      type: String,
    }
  }
}
</script>