<template>
  <div class="modal-mask">
    <div class="modal-wrapper" v-if="congratsShown">
      <div class="modal-container">
        <div class="modal-body">
          <div class="congratulations">
            <img src="/img/ee/baby-pacifier.svg" class="congratulations__img">
            <p>Congratulations!</p>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-wrapper" v-if="!congratsShown">
      <div class="modal-container">
        <div class="modal-header">
          <div class="modal-header__close-button" v-on:click="closeModal()">x</div>
          <div id="title">
            Birth Confirmation
          </div>
<!--          <button type="button" class="btn-close" @click="close">-->
<!--            x-->
<!--          </button>-->
        </div>

        <div class="modal-body">
          <p class="margin: 0">Please submit the following information related to the arrival of your new baby</p>
          <div class="survey-section" id="section-1">
            <div v-if="expectedBirthDate" class="section-header">
              <p style="font-weight: bold">Estimated delivery date</p>
              <p>{{ expectedBirthDate.toDateString() }}</p>
            </div>
            <div v-if="expectedBirthDate" class="section-body">
              <p>Was your baby born on their estimated delivery date?</p>
              <div class="survey-btns">
                <button class="survey-btn" :class=" birthOccurred === 1 ?
                'btn-selected' : 'btn-deselected'" @click="showBirthDateSelector = false; birthOccurred = 1">Yes</button>
                <button class="survey-btn" :class="showBirthDateSelector ?
                'btn-selected' : 'btn-deselected'" @click="showBirthDateSelector = true; birthOccurred = -1">No</button>
              </div>
            </div>
            <div class="section-body" v-if="showBirthDateSelector">
              <div class="date-confirmation">
                <p>Please select your actual delivery date.</p>
                <div style="width: 50px"/>
                <div>
                  <v-date-picker v-model="actualBirthDate" @dayclick="birthOccurred = 0">
                  </v-date-picker>

                </div>
              </div>
            </div>

          </div>

          <div v-if="birthOccurred !== -1" class="survey-section" id="section-2">
            <div class="section-body">
              <p>Can you please confirm the last day you worked?</p>
              <div class="date-confirmation">
                <v-date-picker v-model="lastWorkDate" @dayclick="didLastDayWorked = true"/>
              </div>
            </div>
          </div>

          <div v-if="shouldShowMethodOfDelivery" class="survey-section" id="section-2">
            <div class="section-body">
              <p>Thank you, what was your method of delivery?</p>
              <div class="survey-btns">
                <button class="survey-btn" :class=" deliveryMethod === 'vaginal' ?
                'btn-selected' : 'btn-deselected'" @click="deliveryMethod = 'vaginal'">Vaginal</button>
                <button class="survey-btn" :class=" deliveryMethod === 'cesarean' ?
                'btn-selected' : 'btn-deselected'" @click="deliveryMethod = 'cesarean'">Cesarean</button>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <slot name="footer">
          </slot>
          <button type="button" class="btn-submit" @click="submitAndClose" :disabled="!isFormComplete">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';

import ApiProperties from '../../../src/main/resources/application';

export default {
  name: "BirthConfirmationModal.vue",
  data: function (){
    return {
      expectedBirthDate: this.$props.birthDate,
      actualBirthDate: this.$props.birthDate,
      lastWorkDate: new Date(),
      showBirthDateSelector: false,
      birthOccurred: -1,
      congratsShown: false,
      deliveryMethod: null,
      didLastDayWorked: false
    }
  },
  methods: {
    showCongrats() {
    },
    closeModal() {
      this.$emit('close');
    },
    submitAndClose() {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
        pathVars: {
          itemId: this.itemId
        }
      };

      // `keepLocalTime` is used in order to disregard the user's time zone and set it to America/Los_Angeles. This is
      // done so that dates for forms are displayed consistently regardless of the user's time zone.
      //
      // For example:
      //
      // If a user in the America/Toronto time zone selects January 4, 2023 the date string that will be sent if we
      // simply convert the date to America/Los_Angeles is 2023-01-03T21:00:00.000-08:00.
      //
      // However, when we keep the local time fixed, the date string that is sent is 2023-01-04T00:00:00.000-08:00.
      const birthDate = DateTime.fromJSDate(this.actualBirthDate)
        .setZone('America/Los_Angeles', { keepLocalTime: true })
        .toString();
      const lastWorkDate = DateTime.fromJSDate(this.lastWorkDate)
        .setZone('America/Los_Angeles', { keepLocalTime: true })
        .toString();

      const formData = this.variation === 'BIRTH_CONFIRMATION_BIRTHING_PARENT' ? {
        'responses': {
          'actual_delivery_date': birthDate,
          'delivery_method': this.deliveryMethod,
          'last_work_date': lastWorkDate
        }
      } : {
        'responses': {
          'actual_delivery_date': birthDate,
          'last_work_date': lastWorkDate
        }
      };

      this.$http.post(ApiProperties.api.formResponse, formData, config).then(() => {
        this.congratsShown = true;
        setTimeout(() => this.$emit('close'), 2500);
      });
    },
  },
  mounted: function() {
    if (! this.$props.birthDate) {
      this.birthOccurred = 1;
      this.showBirthDateSelector = true;
    }

    if (this.$props.lastDayOfWork) {
      this.lastWorkDate = this.$props.lastDayOfWork;
    }
  },
  computed: {
    isFormComplete: function () {
      if (this.shouldShowMethodOfDelivery) {
        return this.deliveryMethod && this.didLastDayWorked;
      }

      return this.birthOccurred !== -1 && this.didLastDayWorked;
    },
    shouldShowMethodOfDelivery: function () {
      return this.variation === 'BIRTH_CONFIRMATION_BIRTHING_PARENT' && this.birthOccurred !== -1 && this.didLastDayWorked;
    }
  },
  props: {
    birthDate: Date,
    lastDayOfWork: Date,
    variation: String,
    itemId: Number
  }
};
</script>

<style scoped>
  @import '../scss/modal-forms.scss';

  .modal-container::-webkit-scrollbar{
    display: none;
  }
</style>
