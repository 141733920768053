<template>
  <div class="leave-card d-flex" :class="[leave.color.className, {'selected':selected}]" v-on:click="selectLeave()">
    <div class="leave-icon-container"><div class="leave-icon"><LeaveIcon :active="selected" :icon="leave.leaveIcon"/></div></div>
    <div class="leave-card-content d-flex flex-column pr-2" style="width: 100%">
      <!--<p class="days-remaining" v-if="leave.daysRemaining.show && hasAllBlockLeaveSchedules(leave)">{{leave.daysRemaining.total}} {{leave.daysRemaining.text}}</p>-->

      <div class="d-flex flex-row justify-content-between">
        <h6 class="leave-type m-0" style="line-height: 20px;">{{leave.leaveType}} Leave</h6>
        <p class="date-range" style="font-size: 12px;">{{leave.leaveId}}</p>
      </div>
      <p class="date-range">{{leave.leavePeriod}}</p>
    </div>
  </div>
</template>

<script lang="js">
  import LeaveIcon from "@/components/leaveIcon/LeaveIcon.vue";
  import leaveMixin from '@/mixins/leaveMixin.js';

  export default {
    name: 'LeaveCard',

    data: function() {
      return {
        leaveIcon: "pregnancy"
      }
    },

    props: ['leave','selected'],

    computed: {},

    components: {
      LeaveIcon: LeaveIcon
    },

    mixins: [leaveMixin],

    methods: {
      selectLeave: function() {
        if (!this.$props.selected) {
          this.$root.$emit('SelectLeave', this.leave)
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "../scss/leavetype-icons";
  @import "../scss/variables";
  @import '../../node_modules/bootstrap/scss/functions';
  @import '../../node_modules/bootstrap/scss/variables';
  @import '../../node_modules/bootstrap/scss/mixins';

  .leave-card {
    cursor: pointer;
    background: #F0F1F2;
    min-width: 270px;
    width: 270px;
    height: 100px;
    margin-bottom: 15px;
    margin-right: 15px;

    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #E1E3E4;

    @include media-breakpoint-down(md) {
      margin-right:15px;
    }

    .leave-icon-container {
      padding: 16px 12px 0 12px;
    }

    .leave-card-content {
      padding: 16px 0 0;

      p {
        margin:0;
        padding:0;
        color: #cdcdcd;

        &.date-range {
          color: #969DA0;
          font-size: .675em;
          line-height: 2em;
        }

        &.days-remaining {
          font-weight: 700;
          font-size: .6em;
        }

        &.leave-type {
          color: #000;
          font-size: .65em;
          font-weight: bold;
          line-height: 20px;
          margin: 0px;
        }
      }
    }

    .leave-icon {
      text-align: center;
      border-radius: 6px;
      width: 38px;
      height: 38px;

      i.icon {
        color: #999999;
        font-size: 1.7em;
        line-height: 38px;
      }
    }

    &.selected {
      cursor: default;
      background: #FFFFFF;


      .leave-card-content {
        p {
          &.date-range {
            color: #969DA0;
          }

          &.days-remaining {
            color: #0075a9;
          }

          &.leave-type {
            color: #000;
          }
        }
      }
    }
  }
</style>

