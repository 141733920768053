<template>
  <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_753_426)">
      <path d="M35.3613 32.3112C26.694 34.1322 22.1558 29.8817 18.4437 22.7999C15.9313 15.4899 16.6532 11.2756 18.1244 7.96388C20.5029 2.60988 25.4413 -0.0282282 32.7497 0.297653C40.0581 0.623534 45.9692 3.20067 47.8219 13.4019C48.5204 18.2301 47.8219 21.1847 47.8219 21.1847C46.9683 24.182 44.0936 27.6298 42.6739 28.4781C40.6562 29.6838 37.6657 31.8271 35.3613 32.3112Z" :fill="secondaryColor"/>
      <path d="M10.5175 49.1933C9.58027 44.3466 10.127 39.045 10.5175 37C16.8906 39.5767 27.5957 46.5603 32.1517 49.7301C36.6503 45.7423 48.2927 39.5818 53.5515 37C54.3638 42.3988 53.89 47.3783 53.5515 49.1933C44.1168 53.8558 35.3538 59.6738 32.1517 62C25.5286 56.908 14.9693 51.3405 10.5175 49.1933Z" :stroke="mainColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.5175 30.681C9.58027 25.6405 10.127 20.1268 10.5175 18C16.8906 20.6798 27.5957 27.9427 32.1517 31.2393C36.6503 27.092 48.2927 20.6851 53.5515 18C54.3638 23.6147 53.8899 28.7935 53.5515 30.681C44.1168 35.5301 35.3538 41.5808 32.1517 44C25.5286 38.7043 14.9693 32.9141 10.5175 30.681Z" :stroke="mainColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M31.0616 9.45755L32.4953 5.04508L33.929 9.45755C34.2302 10.3846 35.0941 11.0123 36.0689 11.0123H40.7084L36.955 13.7393C36.1664 14.3123 35.8364 15.3278 36.1376 16.2549L37.5713 20.6674L33.8178 17.9403C33.0292 17.3674 31.9614 17.3674 31.1728 17.9403L27.4193 20.6674L28.853 16.2549C29.1543 15.3278 28.8243 14.3123 28.0357 13.7393L24.2822 11.0123H28.9217C29.8965 11.0123 30.7604 10.3846 31.0616 9.45755Z" :stroke="secondaryColor" stroke-width="2.5" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_753_426">
        <rect width="64" height="64" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'MilitaryIcon',
  props: {
    mainColor: {
      required: true,
      type: String,
    },
    secondaryColor: {
      required: true,
      type: String,
    }
  }
}
</script>
