<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <div class="modal-header__close-button" v-on:click="closeModal()">x</div>
          <div id="title">
            Direct Deposit Authorization
          </div>
        </div>
        <div class="modal-body direct-deposit-instructions-container" v-if="routingNumberInstructionsShown || accountNumberInstructionsShown">
          <div v-if="routingNumberInstructionsShown">
            <p>Your routing number is the first set of nine-digit bank numbers on a check located in the bottom left corner.</p>
            <img src="/img/direct-deposit-routing-instructions.png" />
            <p class="routing-number-label">Routing Number</p>
            <a class="cancel" @click="toggleRoutingNumberInstructionsModalShown">
              Dismiss
            </a>
          </div>
          <div v-else-if="accountNumberInstructionsShown">
            <p>Your account number is the second set of bank numbers on a check located in the bottom right corner.</p>
            <img src="/img/direct-deposit-account-instructions.png" />
            <p class="account-number-label">Account Number</p>
            <a class="cancel" @click="toggleAccountNumberInstructionsModalShown">
              Dismiss
            </a>
          </div>
        </div>
        <div class="modal-body" v-if="!(routingNumberInstructionsShown || accountNumberInstructionsShown)">
          <p style="margin: 0">This form will allow The Larkin Company to make direct deposit payments into your bank account.</p>
          <p style="margin: 0">IMPORTANT: Direct deposit is for U.S. banks only.</p>
          <div class="survey-section">
            <div class="section-body">
              <p>Enter your routing number:</p>
              <div class="input-with-instructions-container">
                <input v-model="achRoutingNumber" placeholder="Routing number" />
                <a v-b-tooltip.hover title="Where is my routing number?" @click="toggleRoutingNumberInstructionsModalShown"><i class="icon icon-info"></i></a>
              </div>
              <div class="invalid-feedback d-block" v-if="fieldError('achRoutingNumber')">
                <span v-if="fieldError('achRoutingNumber')">
                  Invalid routing number.
                </span>
                <span v-else>{{formErrorMessage()}}</span>
              </div>
            </div>
            <div class="section-body">
              <input name="no-autofill-0" type="password" id="no-autofill-0" style="display: none;" value="no-autofill-0" autocomplete="new-password" />
              <p>Enter your account number:</p>
              <div class="input-with-instructions-container">
                <input v-model="achAccountNumber" placeholder="Account number" :type="achAccountNumberFieldType" @focus="unmaskAchAccountNumberField" @blur="maskAchAccountNumberField" />
                <a v-b-tooltip.hover title="Where is my account number?" @click="toggleAccountNumberInstructionsModalShown"><i class="icon icon-info"></i></a>
              </div>
              <p>Confirm your account number:</p>
              <div class="input-with-instructions-container">
                <input v-model="confirmAchAccountNumber" placeholder="Confirm account number" :type="confirmAchAccountNumberFieldType" @focus="unmaskConfirmAchAccountNumberFieldType" @blur="maskConfirmAchAccountNumberFieldType" />
                <a class="layout-only"><i class="icon icon-info"></i></a>
              </div>
              <div class="invalid-feedback d-block" v-if="fieldError('achAccountNumber')">
                <span v-if="fieldError('achAccountNumber')">
                  Invalid account number.
                </span>
                <span v-else>{{formErrorMessage()}}</span>
              </div>
              <div class="invalid-feedback d-block" v-else-if="achAccountNumber != confirmAchAccountNumber && confirmAchAccountNumber != null">
                Account numbers must match.
              </div>
            </div>
            <div class="section-body">
              <p>I hereby authorize The Larkin Company to initiate electronic funds deposits from my financial institution. In the event funds are deposited into my account erroneously, I further authorize The Larkin Company to withdraw from my account the amount of the erroneous deposit. I understand this authorization will remain in effect until I revoke it by written notice to my program administrator, and further acknowledge that since the timing of funds posted to my account may vary, it is my responsibility to check my account before drawing from it. I understand The Larkin Company will apply direct deposit to all benefits eligible for direct deposit.</p>
              <b-form-checkbox class="agree-to-terms-checkbox" v-model="agreesToTerms" type="checkbox">I agree to the terms</b-form-checkbox>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn-submit" @click="submitAndClose" :disabled="!isFormComplete">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ApiProperties from '../../../src/main/resources/application';
  import FormMixin from '@/mixins/formMixin';
  import { required, sameAs } from 'vuelidate/lib/validators';
  import { DateTime } from 'luxon';

  export default {
    name: "DirectDepositAuthorizationModal.vue",
    mixins: [FormMixin],
    data: function() {
      return {
        routingNumberInstructionsShown: false,
        accountNumberInstructionsShown: false,
        achAccountNumberFieldType: 'password',
        confirmAchAccountNumberFieldType: 'password',
        achRoutingNumber: null,
        achAccountNumber: null,
        confirmAchAccountNumber: null,
        agreesToTerms: false,
        submitted: false
      }
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
      maskAchAccountNumberField() {
        this.achAccountNumberFieldType = 'password';
      },
      unmaskAchAccountNumberField() {
        this.achAccountNumberFieldType = 'text';
      },
      maskConfirmAchAccountNumberFieldType() {
        this.confirmAchAccountNumberFieldType = 'password';
      },
      unmaskConfirmAchAccountNumberFieldType() {
        this.confirmAchAccountNumberFieldType = 'text';
      },
      toggleRoutingNumberInstructionsModalShown() {
        this.routingNumberInstructionsShown = !this.routingNumberInstructionsShown;
      },
      toggleAccountNumberInstructionsModalShown() {
        this.accountNumberInstructionsShown = !this.accountNumberInstructionsShown;
      },
      submitAndClose() {
        const config = {
          headers: {
            'Content-Type': 'application/json'
          },
          pathVars: {
            itemId: this.itemId
          }
        };

        const agreementDate = DateTime.now().setZone('America/Los_Angeles').toString();

        const formData = {
          'responses': {
            'ach_routing_number': this.achRoutingNumber,
            'ach_account_number': this.achAccountNumber,
            'ach_authorization_agreement_date': agreementDate
          }
        };

        this.submit(
          'post',
          ApiProperties.api.formResponse,
          config,
          formData,
          (response) => {
            this.submitted = true;
            setTimeout(() => this.$emit('close'), 200);
          },
          (error) => {
            this.routingNumberInstructionsShown = false;
            this.accountNumberInstructionsShown = false;
            this.submitted = false;
          }
        );
      },
    },
    computed: {
      isFormComplete: function () {
        return this.achRoutingNumber && this.achAccountNumber && this.agreesToTerms && (this.achAccountNumber === this.confirmAchAccountNumber);
      }
    },
    props: {
      itemId: Number
    }
  };
</script>

<style scoped>
  @import '../scss/modal-forms.scss';

  .direct-deposit-instructions-container  {
    display: flex;
    justify-content: center;
  }

  .direct-deposit-instructions-container img {
    max-width: 100%;
    padding: 25px;
    display: block;
  }

  .direct-deposit-instructions-container p.routing-number-label {
    text-align: left;
    margin-left: 17.5%;
  }

  .direct-deposit-instructions-container p.account-number-label {
    text-align: right;
    margin-right: 20%;
  }

  .input-with-instructions-container .layout-only {
    visibility: hidden;
  }
</style>
