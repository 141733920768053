<template>
  <div class="modal-mask">
      <div class="popover-container">
        <div class="popover-body" ref="popoverBody">
          <h6>{{ title }}</h6>
          <p>{{ body }}</p>
          <b-button variant="link" class="m-0 p-0" href="#" @click="close">Got it</b-button>
        </div>
      </div>
  </div>
</template>

<script>
  export default {
    name: 'TaskPopover',
    data: function() {
      return {}
    },
    methods: {
      close() {
        this.$emit('close');
      }
    },
    props: {
      title: {
        type: String,
        default: "Complete document"
      },
      body: {
        type: String,
        default: "Please complete and submit the form."
      },
      top: Number,
      left: Number
    },
    watch: {
      top: {
          handler: function() {
            const popoverBody = this.$refs.popoverBody;
            const height = popoverBody.getBoundingClientRect().height;
            popoverBody.style.setProperty('--top', this.top - height + "px");
          }
      },
      left: {
        handler: function() {
          const popoverBody = this.$refs.popoverBody;
          popoverBody.style.setProperty('--left', this.left + "px");
        }
      }
    }
  };
</script>

<style scoped>
  .modal-mask {
    background-color: transparent;
  }

  .popover-body {
    position: absolute;
    background-color: #2534DA;
    border-radius: 4px;
    color: #FFFFFF;
    display: block;
    filter: drop-shadow(0px 2px 8px #0E1F2225);
    padding: 16px;
    top: var(--top);
    left: var(--left);
  }

  .popover-body a {
    color: #FFFFFF;
    font-weight: 500;
    text-decoration: underline;
  }

  .popover-body::after {
    z-index: -99;
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: 0;
    bottom: -16px;
    left: 16px;
    box-sizing: border-box;
    border: 8px solid #2534DA;
    border-color: transparent transparent #2534DA #2534DA;
    transform-origin: 0 0;
    transform: rotate(-45deg);
  }
</style>
