<template>
  <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.67969 14.0703C6.79102 14.1816 6.93945 14.2188 7.125 14.2188C7.27344 14.2188 7.42188 14.1816 7.5332 14.0703L12.877 8.72656C13.0996 8.50391 13.0996 8.0957 12.877 7.87305C12.6543 7.65039 12.2461 7.65039 12.0234 7.87305L7.71875 12.2148V1.15625C7.71875 0.859375 7.42188 0.5625 7.125 0.5625C6.79102 0.5625 6.53125 0.859375 6.53125 1.15625V12.2148L2.18945 7.87305C1.9668 7.65039 1.55859 7.65039 1.33594 7.87305C1.11328 8.0957 1.11328 8.50391 1.33594 8.72656L6.67969 14.0703ZM13.6562 16H0.59375C0.259766 16 0 16.2969 0 16.5938C0 16.9277 0.259766 17.1875 0.59375 17.1875H13.6562C13.9531 17.1875 14.25 16.9277 14.25 16.5938C14.25 16.2969 13.9531 16 13.6562 16Z" fill="#2331D0"/>
  </svg>
</template>

<script>
export default {
  name: 'DownloadIcon',
}
</script>

