<template>
    <component :mainColor="colors.true.main" :secondaryColor="colors.true.secondary" :is="icons[icon.toLowerCase()]"></component>
</template>

<script>

import {
  BereavementIcon,
  MilitaryIcon,
  PersonalIcon,
  PregnancyLeaveIcon,
  ParentalIcon,
  WorkersCompIcon,
  ShortTermDisabilityIcon,
  ReturnToWorkIcon,
  MedicalIcon,
  FamilyPlanIcon,
  WorkAccommodation
} from "./icons";

export default {
  name: "LeaveIcon",
  components: {
    BereavementIcon,
    MilitaryIcon,
    PersonalIcon,
    PregnancyLeaveIcon,
    ParentalIcon,
    WorkersCompIcon,
    ReturnToWorkIcon,
    MedicalIcon,
    FamilyPlanIcon,
    WorkAccommodation
  },
  data: function() {
    return {
      icons: {
        "icon-leave-bereavement": "BereavementIcon",
        "icon-leave-military": "MilitaryIcon",
        "icon-leave-personal": "PersonalIcon",
        "icon-leave-pregnancy": "PregnancyLeaveIcon",
        "icon-leave-parental": "ParentalIcon",
        "icon-leave-workerscomp": "WorkersCompIcon",
        "icon-leave-gradualreturntowork": "ReturnToWorkIcon",
        "icon-leave-medical": "MedicalIcon",
        "icon-leave-familycare": "FamilyPlanIcon",
        "icon-leave-atworkaccomodation": "WorkAccommodation"
      },
      colors: {
        true: {
          main: "black",
          secondary: "#FFD600"
        },
        false: {
          main: "#dcdcdc",
          secondary: "#f3f2f2"
        }
      }
    }
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: false
    }
  }
}

</script>