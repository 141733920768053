<template>
  <div class="d-flex todolist-detail flex-wrap flex-lg-nowrap flex-column">
    <div class="mod">
      <BirthConfirmationModal v-if="displayedForm === 'BIRTH_CONFIRMATION_BIRTHING_PARENT' || displayedForm === 'BIRTH_CONFIRMATION_NON_BIRTHING_PARENT'" @close="closeModal" :birth-date="birthConfirmation.estimatedDeliveryDate" :variation="birthConfirmation.variation" :item-id="birthConfirmation.itemId" :last-day-of-work="birthConfirmation.lastDayOfWork" />
      <DirectDepositAuthorizationModal v-else-if="displayedForm === 'DIRECT_DEPOSIT_AUTHORIZATION'" @close="closeModal" :item-id="directDepositAuthorization.itemId" />
      <TaskPopover v-show="taskPopover.visible" @close="closeTaskPopover" ref="popoverBody" v-bind="taskPopover" />
    </div>
    <div class="d-flex flex-column" :style="{width: isSmallOrLess() ? '100%' : 'fit-content'}">
      <div class="d-flex flex-row align-items-center align-self-end mb-1 mr-2">
        <div v-for="(leave, index) in leaves" v-bind:key="index" :index="index">
          <div class="mr-1" :style="`border-radius: 20px; height: 6px; width: 6px; background-color: ${leave.leaveId === selectedLeave.leaveId ? '#2D3B3E' : '#C3C7C8'}`"><span></span></div>
        </div>
      </div>
      <div class="leave-list d-flex flex-nowrap flex-lg-wrap m-0" :style="{width: isSmallOrLess() ? '100%' : 'fit-content'}">
        <hooper ref="leave-card-navigation" class="d-sm-flex d-lg-none" :itemsToShow="1.3" :items-to-slide="1.3" :centerMode="false">
          <slide v-for="(leave, index) in leaves" v-bind:key="index">
            <div  @click="slideHopperTo('leave-card-navigation', index)">
              <LeaveCard :leave="leave" :selected="leave.leaveId === selectedLeave.leaveId "></LeaveCard>
            </div>
          </slide>
        </hooper>
        <LeaveCard class="d-sm-none d-lg-flex" v-for="(leave, index) in leaves" v-bind:key="index" :leave="leave" :selected="leave.leaveId===selectedLeave.leaveId"></LeaveCard>
      </div>
    </div>

    <div class="todolist-content">
      <div class="d-md-none d-lg-none d-flex flex-row align-items-center justify-content-between px-2" style="width: auto">
        <a @click=" () => { slidePrev('mobile-nav'); selectedActionItemTabIndex -= 1;}"> <i class="icon icon-Arrow-left" :style="{color: selectedActionItemTabIndex == 0 ? '#B1B5B4' : '#2D3B3E', 'font-size': '30px'}"></i></a>
        <div style="width: 300px; overflow: hidden">
          <hooper ref="mobile-nav" style="width: 100%;" :items-to-show="2" :itemsToSlide="1" :centerMode="false">
            <slide style="width: auto; display: inline-block" v-for="(actionItemBucket, index) in todolist.actionItemBuckets" v-bind:key="index">
              <div v-bind:class="{'nav-link-mobile': selectedActionItemTabIndex == index}" class="mx-3" style="width: auto">
                <p @click=" () => { slideHopperTo('mobile-nav', index); selectedActionItemTabIndex = index}" class="p-2 mb-0" :style="{'white-space': 'nowrap', 'font-size': '14px', color:selectedActionItemTabIndex == index ? '#2331D0' : '#2c2e30', fontWeight: '500' }">{{actionItemBucket.name}}</p>
              </div>
            </slide>
          </hooper>
        </div>
        <a @click=" () => { slideNext('mobile-nav'); selectedActionItemTabIndex += 1;}"> <i class="icon icon-Arrow-right" :style="{color: selectedActionItemTabIndex == 4  ? '#B1B5B4' : '#2D3B3E', 'font-size': '30px'}"></i></a>
      </div>
      <b-tabs  style="border: none !important;" ref="action-item-tabs" :nav-class="`scrollable-tabs-nav ${isSmallOrLess() ? 'd-sm-none' : ''}`" v-model="selectedActionItemTabIndex" @changed="actionItemTabsChanged">
        <b-tab :title="actionItemBucket.name" v-for="(actionItemBucket, index) in todolist.actionItemBuckets" v-bind:key="index">
          <div v-if="infoOnlyActionItemBucket(actionItemBucket)" class="larkin-enclosure-box mb-4">
            <div v-for="subcategory in subcategories" v-if="hasActionItemsForType(actionItemBucket.actionItemList, subcategory.types)" class="action-group">
            <div class="action-header d-flex flex-row">
              <h5 class="mr-auto" style="font-size: 14px; font-weight: 500">{{subcategory.title}}</h5>

              <a class="d-flex flex-row align-items-center" v-b-toggle style="text-decoration: none; align-items: center" :href="'#'+subcategory.types[0]">
                <p class="mr-2" style="color: #2C2E30; font-weight: 500; text-transform: none">Remaining ({{ countRemainingItems(actionItemBucket.actionItemList, subcategory.types) }})</p>
                <i class="when-open icon icon-chevron-down" style="font-size: 8px; color: #2D3B3E"></i>
                <i class="when-closed icon icon-chevron-up" style="font-size: 8px; color: #2D3B3E"></i>
              </a>
            </div>
            <b-collapse v-b-toggle="actionItemTabsChanged" visible :id="subcategory.types[0]">
              <div class="action-item-group pb-2" :class="getActionItemStatusClass(actionItem.status)" v-for="(actionItem, innerIndex) in sortObjectsByStatus(getFilteredActionItems(actionItemBucket.actionItemList, subcategory.types))" v-bind:key="innerIndex">
                <b-modal :id="'modal-'+actionItem.id" :title="actionItem.name" footer-class="d-none" body-class="info-modal">
                  <p v-html="actionItem.description" v-linkified></p>
                </b-modal>
                <div class="action-item-description my-3 w-75" :ref="actionItem.id">
                  <h6 class="mb-0">{{actionItem.name || getTruncatedDescription(actionItem.description)}} </h6>
                  <p class="body-sm mb-2" v-html="actionItem.description" v-linkified></p>
                  <div>
                    <a style="color: #2331D0; font-size: 14px; text-decoration: none; width: fit-content;" class="mb-2 my-1 d-flex justify-content-center align-items-center"  v-if="!isSmallOrLess() && showDownloadItem(actionItem)" :href="downloadFileLink(actionItem.id)" :ref="'item-download-'+actionItem.id" download><DownloadIcon class="mr-2" /> Download</a>
                  </div>
                    <div v-if="canUpload(actionItem)">
                    <FileUpload :actionItem="actionItem" :fileUploadId="uploadFileInputId(actionItem.id)" />
                  </div>
                </div>

                <div class="action-item-buttons">
                  <a style="color: #2331D0; font-size: 14px; text-decoration: none;" class="mb-2 my-1 d-flex justify-content-center align-items-center"  v-if="showDownloadItem(actionItem) && isSmallOrLess()" :href="downloadFileLink(actionItem.id)" :ref="'item-download-'+actionItem.id" download><DownloadIcon class="mr-2" /> Download</a>
                  <div v-if="!showDownloadItem(actionItem)"></div>
                  <button v-if="showWorkflowItem(actionItem)" class="action-item-button  btn-larkin-yellow" style="align-self: end" @click="goWorkflow(actionItem)"><p class="body-sm px-2"><i class="icon icon-pencil-writing-on-paper pr-1"></i> Fill and Sign</p> </button>
                  <button v-if="showFormToComplete(actionItem)" class="action-item-button  btn-larkin-yellow" style="align-self: end" @click="completeForm(actionItem)"><p class="body-sm px-2"><i class="icon icon-pencil-writing-on-paper pr-1"></i> Fill and Sign</p></button>
                  <button v-if="showUploadItem(actionItem)" class="action-item-button  btn-larkin-yellow" style="align-self: end"@click="executeUploadFileForm(uploadFileInputId(actionItem.id))"><p class="body-sm px-2"> <i class="icon icon-upload pr-1"></i> Upload</p></button>
                  <button @click="updateStatus(actionItem.id, isCompleted(actionItem) ? updateStatusObj.PREVIOUS : updateStatusObj.NEXT)" style="align-self: end" v-if="actionItem.status === 'PENDING_CLIENT'" class="action-item-button btn-larkin-yellow px-2 py-1"><p class="body-sm px-2"> <i class="icon icon-grey-check pr-1"></i>Confirm</p></button>
                  <button v-if="getActionItemStatusClass(actionItem.status) === 'completed'" disabled style="background-color: #E1E3E4; color: #969DA0;" class="action-item-button  btn-larkin-yellow"><p class="body-sm m-1 px-2"><i class="icon icon-grey-check pr-1"></i>Completed</p></button>
                </div>
              </div>
            </b-collapse>
          </div>
          </div>

          <div v-if="getFilteredActionItems(actionItemBucket.actionItemList, ['INFO_ONLY']).length != 0" class="d-flex flex-column resources-container">
            <div class="d-flex flex-column w-100">
              <h6 style="border-bottom: 2px solid #D8CEC0" class="body-md mb-3">KEY RESOURCES AND REMINDERS</h6>
            </div>
            <b-row class="resources-list px-3" :cols="isSmallOrLess() ?   '1' : '3'">
              <b-col :ref="`resource-item-${actionItemBucket.name}-${innerIndex}`" class="p-0" v-for="(actionItem, innerIndex) in getFilteredActionItems(actionItemBucket.actionItemList, ['INFO_ONLY'])" >
                <div class="resource-item" data-expanded="false">
                  <div class="text-container">
                    <h6 class="m-0" style="text-wrap: nowrap; overflow: hidden">{{actionItem.name}}</h6>
                    <p class="body-sm m-0" style="height: 42px;" v-html="actionItem.description"  v-linkified></p>
                  </div>
                  <div class="d-flex flex-row align-items-center justify-content-between mr-4">
                    <a @click="expandRow(innerIndex, actionItemBucket.name, isSmallOrLess() ?   1 : 3)" class="resource-item-button mr-2 d-flex justify-content-center" v-b-toggle style="height: 29px" v-if="actionItem.description.length > (isSmallOrLess() ? 80 : 100)">
                      <div class="d-flex flex-row align-items-center justify-content-between" v-if="!isResourceItemExpanded(`resource-item-${actionItemBucket.name}-${innerIndex}`)">
                        Show More
                        <i class="icon icon-chevron-down ml-1" style="font-size: 8px; color: #2331D0; margin-left: 2px;"></i>
                      </div>
                      <div class="d-flex flex-row align-items-center justify-content-between" v-else-if="isResourceItemExpanded(`resource-item-${actionItemBucket.name}-${innerIndex}`)">
                        Show Less
                        <i class="icon icon-chevron-up ml-1" style="font-size: 8px; color: #2331D0; margin-left: 2px;"></i>
                      </div>
                    </a>
                    <a :href="downloadFileLink(actionItem.id)" :ref="'item-download-'+actionItem.id" download class="mb-2 my-1 resource-item-button "  v-if="showDownloadItem(actionItem)"><DownloadIcon class="mr-2" /> Download</a>
                  </div>
                </div>
                <div class="mt-3">
                  <hr />
                </div>
              </b-col>
            </b-row>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <div class="w-100 justify-content-center d-flex mt-5" v-if="todolist.actionItemBuckets.length === 0">
      <p>No to do list items to complete, please contact your program administrator for more information.</p>
    </div>

<!--    <b-modal :id="'workflowModal'" :title="'Hello Works Workflow'" footer-class="d-none" body-class="workflow-modal">-->
<!--      <iframe ref="workflowIframe" width="100%" height="100%" allowtransparency="true" style="border:0;"></iframe>-->
<!--    </b-modal>-->
  </div>

</template>

<script lang="js">
  import LeaveCard from '@/components/LeaveCard.vue'
  import ApiProperties from '../../../src/main/resources/application';
  import leaveMixin from '@/mixins/leaveMixin.js';
  import FileUpload from '@/components/FileUpload.vue';
  import { Hooper, Slide } from 'hooper';
  import '../scss/hooper.scss'
  import breakpointMixin from "@/mixins/breakpointMixin";
  import get from 'just-safe-get'
  import BirthConfirmationModal from "@/components/BirthConfirmationModal";
  import DirectDepositAuthorizationModal from "@/components/DirectDepositAuthorizationModal";
  import DownloadIcon from "@/components/DownloadIcon.vue";
  import TaskPopover from "@/components/TaskPopover";

  export default {
    name: 'TodoList',

    data: function() {
      return {
        helloWorksPopup: {
          window: null,
          width: window.innerWidth,
          height: window.innerHeight,
          top: 0,
          left: 0
        },
        isWaiting: false,
        popupInterval: null,
        popupIsOpen: false,
        errors: [],
        leaves: [],
        selectedLeave: {},
        selectedActionItemTabIndex: null,
        selectedLeaveIndex: 0,
        previousModalId: null,
        todolist: {
          actionItemBuckets: []
        },
        updateStatusObj: {
          NEXT: "NEXT",
          PREVIOUS: "PREVIOUS"
        },
        subcategories: [
          {
            title: "Documents to Submit",
            types: ["UPLOAD", "WORKFLOW"]
          },
          {
            title: "Tasks to complete",
            types: ["CONFIRM", "FORM"]
          },
        ],
        isMouseDown: false,
        startX: null,
        scrollLeft: null,
        navElement: null,
        portal: {
          authenticatedUrl: "",
          open: false
        },
        displayedForm: null,
        birthConfirmation: {
          itemId: null,
          estimatedDeliveryDate: null,
          variation: null,
          lastDayOfWork: null
        },
        directDepositAuthorization: {
          itemId: null
        },
        defaultActionExecuted: false,
        taskPopover: {
          visible: false,
          top: 0,
          left: 0,
        }
      }
    },

    props: {
      defaultAction: String,
      taskId: String // the string value should be an integer
    },

    watch: {
      selectedLeave: function(leave) {
        this.getToDoItems(leave.leaveId)
        this.actionItemTabsChanged()
      },
      popupIsOpen: function(newVal, oldVal) {
        console.log(`We have oldVal=${oldVal} and newVal=${newVal}`)
        if (oldVal===true && newVal===false) {
          console.log("Popup was closed.")
          clearInterval(this.popupInterval)
          //poll to get item status update and then update todoitems (but we have to limit the poll to like 30 seconds or so.
          //TODO: we can update the todo items here since the popup window is closed, but we also need know whether HW
          // called back yet and the status was updated, so holding off on this. This will most likely be another limited time poller
          // to figure out if the status on this item has changed (we will somehow need to save the actionItem id in a var)
        } else {
          console.log("Popup was opened.")
          // alert("Opening pop up, make sure to disable your popup blocker")
        }
      }
    },

    computed: {},

    components: {LeaveCard, DownloadIcon, FileUpload, Hooper, Slide, BirthConfirmationModal, DirectDepositAuthorizationModal, TaskPopover},

    mixins: [leaveMixin, breakpointMixin],

    methods: {
      infoOnlyActionItemBucket: function(actionItemBucket) {
        let nonInfoOnlyItems = actionItemBucket.actionItemList.filter((item) => {
          if(item.type !== "INFO_ONLY") {
            return item;
          }
        })

        if(nonInfoOnlyItems.length === 0) {
          return false;
        }

        return true;
      },
      isResourceItemExpanded: function (id) {
        let reference = this.$refs[id]
        if(!reference || reference.length === 0) {
          return false;
        }
        if(reference[0].childNodes[0].classList.contains('expanded-item')) {
          return true;
        }
        return false;
      },
      slideNext: function(reference) {
        this.$refs[reference].slideNext();
      },
      slidePrev: function (reference) {
        this.$refs[reference].slidePrev();
      },
      slideHopperTo: function (reference, index) {
        this.$refs[reference].slideTo(index);
      },
      pollForClosedWorkflowPopup: function() {
        this.popupInterval = setInterval(() => {
          //do not reduce this, it wont work
          if (this.helloWorksPopup.window.closed) {
            this.popupIsOpen = false
            console.log("Popup closed.")
          } else {
            this.popupIsOpen = true
          }
        }, 3000)
      },

      handleResize: function() {
        // console.log(`New height set to ${window.innerHeight} and width to ${window.innerWidth}`)
        this.setHelloWorksPopupDimensions()

        if (this.helloWorksPopup.window) {
          console.log("The popup is open so we should resize it.")
          this.helloWorksPopup.window.moveTo(this.helloWorksPopup.left, this.helloWorksPopup.top);
          this.helloWorksPopup.window.resizeTo(this.helloWorksPopup.width, this.helloWorksPopup.height);
        }

        if (this.taskPopover.visible && this.taskId) {
          this.$nextTick(() => {
            this.showTaskPopover(this.taskId);
          });
        }
      },

      getRowIndexes: function(index) {
        const startIndex = Math.floor(index / 3) * 3;
        const endIndex = startIndex + 2;
        const rowIndexes = [];
        for (let i = startIndex; i <= endIndex; i++) {
          rowIndexes.push(i);
        }
        return rowIndexes;
      },

      // Toggle the "expanded-item" class for the given element
      toggleExpandedClass: function(element) {
        const classList = element.classList;
        classList.contains("expanded-item") ? classList.remove("expanded-item") : classList.add("expanded-item");
      },

      // Get the DOM reference of the resource item by the given index and actionItemBucket
      getResourceItemRef: function(index, actionItemBucket) {
        let ref = this.$refs[`resource-item-${actionItemBucket}-${index}`];
        if(ref) return ref[0].childNodes[0];

        return null;
      },

      expandRow: function(index, actionItemBucket, columns) {
        // If there is more than one column, get all related resource item references and toggle their expanded state
        if (columns > 1) {
          const rowIndexes = this.getRowIndexes(index);
          const resourceItemRefs = rowIndexes.map((index) => this.getResourceItemRef(index, actionItemBucket));

          resourceItemRefs.forEach((ref) => {
            if(ref) {
              this.toggleExpandedClass(ref)
            }
          });
        } else {
          // If there is only one column, toggle the expanded state of the single resource item reference
          const singleResourceItemRef = this.getResourceItemRef(index, actionItemBucket);
          this.toggleExpandedClass(singleResourceItemRef);
        }

        this.$forceUpdate();
        return;
      },

      showUploadItem: function(item) {
        return (this.isUploadPendingClient(item))
      },

      showDownloadItem: function(item) {
        return (item.activeDownload && !item.workflowEnabled)
      },

      showWorkflowItem: function(item) {
        if (!item.workflowEnabled) {
          return false
        }

        return this.isWorkflowPendingClient(item) || this.isWorkflowStarted(item)
      },

      showFormToComplete: function(item) {
        return item.formType && item.status === 'FORM_PENDING_CLIENT';
      },

      sortObjectsByStatus(list) {
        const order = ['COMPLETED', 'UPLOAD_COMPLETED', 'WORKFLOW_COMPLETED', 'FORM_COMPLETED', 'UPLOAD_PENDING_LARKIN'];
        return list.sort((a, b) => order.indexOf(a.status) - order.indexOf(b.status) || list.indexOf(a) - list.indexOf(b));
      },

      completeForm: function(item) {
        const formType = item.formType;
        if (formType === "BIRTH_CONFIRMATION_BIRTHING_PARENT" || formType === "BIRTH_CONFIRMATION_NON_BIRTHING_PARENT") {

          const filteredLeaves = this.leaves.filter(l => l.leaveId === item.leaveId);
          if (filteredLeaves.length === 1 && filteredLeaves[0].estimatedDeliveryDate) {
            this.birthConfirmation.estimatedDeliveryDate = new Date(filteredLeaves[0].estimatedDeliveryDate);

            if (filteredLeaves[0].details[0].lastWorkDate) {
              this.birthConfirmation.lastDayOfWork = new Date(filteredLeaves[0].details[0].lastWorkDate);
            }
            else {
              this.birthConfirmation.lastDayOfWork = null;
            }
          }
          else {
            this.birthConfirmation.estimatedDeliveryDate = null;
          }

          this.birthConfirmation.variation = item.formType;
          this.birthConfirmation.itemId = item.id;

        }
        else if (formType === "DIRECT_DEPOSIT_AUTHORIZATION") {
          this.directDepositAuthorization.itemId = item.id;
        }

        this.displayedForm = item.formType;
      },

      closeModal: function() {
        this.displayedForm = null;
        this.getToDoItems(this.selectedLeave.leaveId);
      },

      closeTaskPopover: function() {
        this.taskPopover = { visible: false };
      },

      setHelloWorksPopupDimensions: function() {
        let height = window.innerHeight
        let width = window.innerWidth

        this.helloWorksPopup.height = (height<600) ? height : 600;

        if (this.isSmallOrGreater()) {
          this.helloWorksPopup.width = 576
          this.helloWorksPopup.left = (width/2)-(this.helloWorksPopup.width/2);
          this.helloWorksPopup.top = (height/2)-(this.helloWorksPopup.height/2);
        } else {
          this.helloWorksPopup.width = window.innerWidth
          this.helloWorksPopup.left = 0;
          this.helloWorksPopup.top = (height/2)-(this.helloWorksPopup.height/2);
        }
      },

      //checks to see if we should resetWorkflow (if completed on the esig side, but not marked as complete from a larkin admin)
      goWorkflow: function(item) {
        // alert('Opening pop up, make sure to disable your popup blocker');
        //reset and initiate can cause a hibernate optimistic locking exception
        // if (this.isWorkflowPendingLarkin(item)) {
        //   this.resetAndInitiateWorkflow(item.id)
        // } else {
        //   this.initiateOrContinueWorkflow(item.id)
        // }

        //were going to fake the status of this workflow to prevent clicking the edit icon again.
        item.status = this.actionItemStatus.WORKFLOW_PENDING_LARKIN
        this.initiateOrContinueWorkflow(item.id)
      },

      initiateOrContinueWorkflow: function(itemId) {
        let pathParams = {pathVars: {itemId: itemId}};

        this.$http.get(ApiProperties.api.todoItemByIdWithWorkflow, pathParams).then(response => {
          let todoItem = response.data
          let authenticatedUrl = get(todoItem, 'activeUpload.workflowInstance.authenticatedUrl')
          if (authenticatedUrl!==null) {
            this.helloWorksPopup.window = window.open(authenticatedUrl, "_blank")

            if (!this.helloWorksPopup.window) {
              alert("It looks like you’re using a pop up blocker\nPlease note some popup blockers will prevent you from being able to open the window to support e-signature.  Please disable your pop up blocker, refresh the and click ‘Fill and Sign’ again.")
              console.log("Your browser is blocking popups, please allow popups.")
            } else {
              this.pollForClosedWorkflowPopup()
            }
          }
        }).catch(e => {
          this.errors.push(e)
        })
      },

      /* This is here and ready to use, but currently have no spot for it in web design) */
      resetAndInitiateWorkflow: function(itemId) {
        let pathParams = {pathVars: {itemId: itemId}};

        this.$http.get(ApiProperties.api.todoResetWorkflow, pathParams).then(response => {
          let item = response.data
          if (item.id) {
            this.initiateOrContinueWorkflow(item.id)
          }
        }).catch(e => {
          this.errors.push(e)
        })
      },

      getTruncatedDescription(desc, length) {
        if (desc.length > (length || 100)) {
          return desc.substring(0, length || 100) + '...';
        }

        return desc;
      },

      onTouchStart(event) {
        this.startX = event.touches[0].pageX - this.navElement.offsetLeft;
        this.scrollLeft = this.navElement.scrollLeft;
      },

      onTouchMove(event) {
        event.preventDefault();
        const x = event.touches[0].pageX - this.navElement.offsetLeft;
        const speed = 1; // higher number means faster scrolling
        const walk = (x - this.startX) * speed;
        this.navElement.scrollLeft = this.scrollLeft - walk;
      },

      onMouseDown(event) {
        this.isMouseDown = true;

        this.startX = event.pageX - this.navElement.offsetLeft;
        this.scrollLeft = this.navElement.scrollLeft;
      },

      onMouseUp(event) {
        this.isMouseDown = false;
      },

      onMouseLeave(event) {
        this.isMouseDown = false;
      },

      onMouseMove(event) {
        if (!this.isMouseDown) return;

        event.preventDefault();
        const x = event.pageX - this.navElement.offsetLeft;
        const speed = 1.5; // higher number means faster scrolling
        const walk = (x - this.startX) * speed;
        this.navElement.scrollLeft = this.scrollLeft - walk;
      },

      descriptionLink: function(actionItem) {
        if (this.isConfirm(actionItem)) {
          let updateStatus = this.isCompleted(actionItem) ? this.updateStatusObj.PREVIOUS : this.updateStatusObj.NEXT;
          this.updateStatus(actionItem.id, updateStatus)
        }
      },

      showTaskPopover: function(taskId, title, body) {
        const rect = this.getActionItemRowRect(taskId);
        Object.assign(this.taskPopover, { visible: true, top: rect.top, left: rect.left });

        if (title) {
          this.taskPopover.title = title;
        }

        if (body) {
          this.taskPopover.body = body;
        }
      },

      updateStatus: function(id, statusUpdate) {
        this.$http.post(ApiProperties.api.todoUpdateItemStatus, {id, statusUpdate}).then(response => {
          this.getToDoItems(this.selectedLeave.leaveId)
        }).catch(e => {
          this.errors.push(e);
        })
      },

      executeUploadFileForm: function(inputElementId) {
        let fileInputEl = document.getElementById(inputElementId);
        fileInputEl.click();
      },

      getFilteredActionItems(actionItems, types) {
        if (types===undefined) {
          return []
        }
        return actionItems.filter(actionItem => types.includes(actionItem.type))
      },

      getActionItemStatusClass: function(status) {
        const completedStatusList = ['COMPLETED','UPLOAD_COMPLETED','WORKFLOW_COMPLETED','FORM_COMPLETED', 'UPLOAD_PENDING_LARKIN', 'WORKFLOW_PENDING_LARKIN']

        return (completedStatusList.includes(status)) ? 'completed' : 'incomplete'
      },

      hasActionItemsForType(actionItems, types) {
        let items = this.getFilteredActionItems(actionItems, types)

        return items.length>0;
      },

      countRemainingItems(actionItems, types) {
        if (types===undefined)
          return 0

        let doneActionItems = actionItems.filter(actionItem => types.includes(actionItem.type)).filter(actionItem => (!this.isCompleted(actionItem)))

        return doneActionItems.length
      },

      processLeaves(allLeaves) {
        let leaves = []
        let formatOptions = {
          month: 'numeric',
          day: 'numeric',
          year: '2-digit'
        };

        if (allLeaves!==null) {
          for (let i=0; i<allLeaves.length; i++) {
            let leave = allLeaves[i];
            // add the leave period for display
            leave.leavePeriod = this.getLeaveCardDateRangeString(leave, formatOptions)
            leave.daysRemaining = this.getDaysRemaining(leave)
            leave.leaveIcon = this.getLeaveIconByLeaveCoverageType(leave.leaveType)
            leave.color = this.getLeaveColorCodeObject(i)

            leaves.push(leave);
          }
        }

        return leaves
      },

      getLeavesByPortalUser: function() {
        let urlWithGetParams = ApiProperties.api.leavesByPortalUser+"?closed=false";

        this.$http.get(urlWithGetParams).then(response => {
          this.leaves = this.processLeaves(response.data)
          this.selectedLeave = (this.leaves.length>0) ? this.leaves[0] : null
        }).catch(e => {
          this.errors.push(e);
        })
      },

      getToDoItems: function(leaveId) {
        let pathParams = {pathVars: {leaveId: leaveId}};

        this.$http.get(ApiProperties.api.todoListByLeave, pathParams).then(response => {
          this.todolist = response.data
        }).catch(e => {
          this.errors.push(e)
        })
      },

      actionItemTabsChanged: function() {
        this.selectedActionItemTabIndex = 0;
      },

      scrollToActionItemTab: function(tabIndex) {
        this.navElement.children[tabIndex].scrollIntoView({ behaviour: 'smooth' });
      },

      scrollToActionItemRow: function(actionItemId) {
        this.$refs[actionItemId][0].scrollIntoView({ behaviour: 'smooth', block: 'center'});
      },

      getActionItemRowRect: function(actionItemId) {
        // The ref is in a v-for so it must be in an array
        const element = this.$refs[actionItemId][0];
        const rect = element.getBoundingClientRect();
        return rect;
      }
    },

    mounted: function() {
      this.navElement = this.$refs["action-item-tabs"].$el.querySelector("ul");

      this.navElement.addEventListener("mousedown", this.onMouseDown);
      this.navElement.addEventListener("mouseup", this.onMouseUp);
      this.navElement.addEventListener("mousemove", this.onMouseMove);
      this.navElement.addEventListener("mouseleave", this.onMouseLeave);

      this.navElement.addEventListener("touchstart", this.onTouchStart);
      this.navElement.addEventListener("touchmove", this.onTouchMove);

      window.addEventListener('resize', this.handleResize);

      if (this.defaultAction === 'birthConfirmation' && !this.defaultActionExecuted) {
        const leavesUrl = ApiProperties.api.leavesByPortalUser+"?closed=false";

        this.$http.get(leavesUrl).then(leavesResponse => {

          const filteredLeaves = leavesResponse.data
              .filter(l => l.closed===false)
              .filter(l => l.leaveType.indexOf("Parental") > -1 || l.leaveType.indexOf("Pregnancy") > -1)
              .flatMap(o => o);

          filteredLeaves.forEach(l => {
            const pathParams = {pathVars: {leaveId: l.leaveId}};

            this.$http.get(ApiProperties.api.todoListByLeave, pathParams).then(response => {
              const todos = response.data;
              const allTasks = todos.actionItemBuckets.map(b => b.actionItemList).flatMap(o => o);

              const needles = allTasks.filter(t => t.id===parseInt(this.taskId));

              if (needles.length!==1) {
                console.log("Could not find birthConfirmation task");
                return;
              }

              const needle = needles[0];
              if (l.estimatedDeliveryDate) {
                this.birthConfirmation.estimatedDeliveryDate = new Date(l.estimatedDeliveryDate);
              }
              else {
                this.birthConfirmation.estimatedDeliveryDate = null;
              }

              if (l.details[0].lastWorkDate) {
                this.birthConfirmation.lastDayOfWork = new Date(l.details[0].lastWorkDate);
              }
              else {
                this.birthConfirmation.lastDayOfWork = null;
              }

              this.birthConfirmation.itemId = needle.id;
              this.birthConfirmation.variation = needle.formType;
              this.displayedForm = needle.formType;
              this.defaultActionExecuted = true;
            });
          });
        });
      } else if (this.defaultAction === 'showTask' && !this.defaultActionExecuted) {
        const leavesUrl = ApiProperties.api.leavesByPortalUser+"?closed=false";
        this.$http.get(leavesUrl).then(leavesResponse => {

          const filteredLeaves = leavesResponse.data
              .filter(l => l.closed===false)
              .flatMap(o => o);

          filteredLeaves.forEach(l => {
            const pathParams = {pathVars: {leaveId: l.leaveId}};

            this.$http.get(ApiProperties.api.todoListByLeave, pathParams).then(response => {
              const leave = response.data;
              const actionItemBuckets = leave.actionItemBuckets;

              let containingLeave;
              let bucketIndex;
              for (const [index, actionItemBucket] of actionItemBuckets.entries()) {
                const tasks = actionItemBucket.actionItemList;
                const candidateTasks = tasks.filter(task => task.id === parseInt(this.taskId));
                if (candidateTasks.length === 1) {
                  containingLeave = leave;
                  bucketIndex = index;
                  break;
                }
              }

              if (containingLeave == null) {
                return;
              }

              this.selectedLeave = containingLeave;

              // Need a delay since the task info isn't available yet
              setTimeout(() => {
                // FIXME: This will fail if the leave data/todo list isn't fetched before the timeout period
                this.selectedActionItemTabIndex = bucketIndex;
                this.$nextTick(() => {
                  this.scrollToActionItemTab(this.selectedActionItemTabIndex);
                  this.scrollToActionItemRow(this.taskId);
                  this.showTaskPopover(this.taskId);
                  this.defaultActionExecuted = true;
                });
              }, 500);
            });
          });
        });
      }
    },

    beforeDestroy: function() {
      console.log("Were in the before destroy method...")
      clearInterval(this.popupInterval);

      this.navElement.removeEventListener("mousedown", this.onMouseDown);
      this.navElement.removeEventListener("mouseup", this.onMouseUp);
      this.navElement.removeEventListener("mousemove", this.onMouseMove);
      this.navElement.removeEventListener("mouseleave", this.onMouseLeave);

      this.navElement.removeEventListener("touchstart", this.onMouseDown);
      this.navElement.removeEventListener("touchmove", this.onMouseMove);

      window.removeEventListener('resize', this.handleResize);
    },

    created: function() {
      this.setHelloWorksPopupDimensions()
      this.getLeavesByPortalUser()

      this.$root.$on('SelectLeave', (leave) => {
        this.selectedLeave = leave
      });

      this.$root.$on('StateChange', (item) => {
        this.getToDoItems(this.selectedLeave.leaveId)
      })
    },
  }
</script>

<style lang="scss">
@import "../scss/variables";
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';

.info-modal {
  &.modal-body {
    p {
      a {
        text-decoration: underline;
      }
    }
  }
}

.action-item-button {
  padding: .25rem .5rem .25rem .5rem !important;
  align-self: end;
  p {
    font-weight: 500 !important;
    margin: .25rem;

    i {
      font-size: 18px;
    }
  }
}

.nav-link-mobile {
  position: relative;
  display: inline-block; /* Set the container to be an inline block */
}


.nav-link-mobile::after {
  background-color: #2331D0;
  content: " ";
  position: absolute;
  z-index: 99;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transform: translateY(calc(100% - 4px)); /* Adjust vertical position to bottom */
}

.expanded-item {
  height: 170px !important;

  & .text-container {
    & .body-sm {
      transition: height .5s;
      height: auto !important;
      overflow: visible;
      -webkit-line-clamp: 9 !important;

      -moz-transition: height 1s ease;
      -webkit-transition: height 1s ease;
      -o-transition: height 1s ease;
      transition: all 1s ease;
    }
  }
}

.resources-container {
  background-color: #ECE7E0;
  border-radius: 6px;
  padding: 16px 40px 16px 40px;
  border: 1px solid #CACACA;

  .resource-item-button {
    color: #2331D0;
    font-size: 14px;
  }

  .resources-list {
    .resource-item {
      padding-right: 15px;
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        -moz-transition: height 1s ease;
        -webkit-transition: height 1s ease;
        -o-transition: height 1s ease;
        transition: height 1s ease;
      }
    }
  }
}

.workflow-modal {
  width: 400px;
  height: 500px;
  &.modal-body {
    padding:0;
  }
}

.action-item-buttons {
  display: flex;
  width: 25%;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}

.todolist-detail {
  margin: 0px 26px 32px 26px;
  overflow: hidden;
  padding-top: 46px;
  max-width: 1192px;

  .leave-list {
    margin: 0 16px 0 0;
    align-content: flex-start;
  }

  .resource-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 116px;
  }

  .todolist-content {
    .larkin-enclosure-box {
      font-size: .65em;
      padding: 12px 40px 16px 40px;
    }

    .leave-title {
      color: #2c2e30;
      font-size: .82rem;

      i {
        color:#999;
        font-size:1.35em;
        line-height:0;
        vertical-align: middle;
        outline:none;
      }
    }

    .nav {
      &.scrollable-tabs-nav {
        flex-wrap: nowrap;
        overflow: hidden;
      }

      &.nav-tabs {
        min-width:25px;
        margin-left: 25px;
        align-content: baseline;
        border-bottom: none;

        .nav-item {
          white-space: nowrap;
          font-size: .7em;

          .nav-link {
            font-weight: 500;
            color: #2D3B3E;
          }
          .nav-link.active {
              color: #2331D0;
              &::after {
                background-color: #2331D0;
              }
              z-index: 0;
          }
        }
      }
    }

    .action-group {
      margin: 10px 0 25px;

      .action-header {
        border-bottom: 2px solid #E1E3E4;

        h5, p, i {
          margin:0 0 3px 0;
          font-size: .95em;
          line-height:1.5em;
          text-transform: uppercase;
          color: #2C2E30;
        }

        h5 {
          font-weight: 700;
        }
        p, i {
          color: #999;
        }

        i {
          font-size: 1.1em;
        }

        .collapsed > .when-open,
        .not-collapsed > .when-closed {
          display: none;
        }

      }

      .action-item-group {
        display: flex;
        flex-direction: row;
        padding: 0px 16px 0px 16px;
        border-bottom: 1px solid #E1E3E4;
        cursor: default !important;

        &:last-child {
          border-bottom: 1px solid #fff;
        }

        .action-item-select {
          text-align: center;
          font-size: 1.4em;
          display: flex;
          flex-direction: column;
          justify-content: center;
          resize: vertical;

          i {
            color: #0075A9;
          }
        }

        .action-item-description {
          display: flex;
          flex-direction: column;
          justify-content: center;
          resize: vertical;

          p {
            margin:0;
            color: #2C2E30;
            line-height: 1.3em;

            &.small-label {
              font-size: 1.1em;
              color: #999;
            }
          }
        }

        .action-item-icons {
          height:48px;
          line-height: 48px;
          vertical-align: center;
          text-align: right;
          font-size:1.5em;
          flex: 0 0 150px;

          a {
            margin: 0 8px;
          }
        }

        &.completed {
          .action-item-select {
            i {
              color: #999;
            }
          }

          .action-item-description {
            color: #999;
            p {
              color: #999;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .todolist-detail {
    .leave-list {
      flex: 1 1 100%;
    }
    .todolist-content {
      width: 100%;
    }
  }
}


@include media-breakpoint-down(sm) {
  .leave-list {
    padding-left: 16px;
  }
  .action-item-primary-button {
    margin-left: auto;
  }

  .btn-larkin-yellow {
    .body-sm {
      font-size: 14px;
    }
  }

  .action-item-buttons {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  .resource-item {
    display: flex;
    flex-direction: column;
    height: 116px;

    p {
      height: auto!important;
    }
  }

  .todolist-detail {
    margin: 0px;

    .todolist-content {
      .larkin-enclosure-box {
        padding: 0px 16px 0px 16px;
      }
      .action-group {
        .action-item-group {
          display: flex;
          flex-direction: column;
          padding: 0px;
          .action-item-description {
            width: 100% !important;
            p {
              font-size: 14px;
              line-height: 1em;

              &.small-label {
                font-size: .9em;
              }
            }
          }
          .action-item-icons {
            flex: 0 0 0;
            a {
              margin:0 2px;
            }
          }
        }
      }
    }
  }
  .scrollable-tabs-nav {
    display: none;
  }
}


</style>
